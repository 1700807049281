<!--
 * @Description:我的 个人信息 手机号修改 selfChangePhone
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-07-31 10:33:59
 * @LastEditors: zhoucheng
-->
<template>
  <div class='body'>
    <van-nav-bar title="手机换绑"
                 left-text="返回"
                 left-arrow
                 @click-left="handleClickTopBack" />
    <van-row class="lineOne">
      <img src="@/assets/wxSelfInfo/phone.png" />
    </van-row>
    <van-row class="lineTwo">
      <span>{{phoneNumber}}</span>
    </van-row>
    <van-field v-model="confirmForm.phoneNum"
               placeholder="请输入手机号">
      <template #left-icon>
        <img src="@/assets/wxSelfInfo/shoujihao.png"
             class="img">
      </template>
    </van-field>
    <van-field v-model="confirmForm.pinNumber"
               center
               placeholder="请输入短信验证码">
      <template #left-icon>
        <img src="@/assets/wxSelfInfo/yanzhengCode.png"
             class="img">
      </template>
      <template #button>
        <van-button size="small"
                    class="button"
                    :disabled="pinButDisabled"
                    @click="seedPin">{{pinButText}}</van-button>
      </template>
    </van-field>
    <van-button @click="handleClickConfirm"
                class="confirmBut-box">
      确认更换
    </van-button>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
export default {
  // import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
    // 这里存放数据
    return {
      userInfo: {}, // 用户信息
      phoneNumber: '', // 当前手机号
      confirmForm: {
        phoneNum: '',
        pinNumber: ''
      }, // 确认更换参数
      pinButDisabled: false,
      pinButText: '发送验证码',
      second: 60,
      regex: /^(13[0-9]{9})|(15[0-9]{9})|(17[0-9]{9})|(18[0-9]{9})|(19[0-9]{9})$/
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {
    // 如果倒计时结束 时间复位
    second (val) {
      if (val === 0) {
        this.second = 60
      }
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () { },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    if (this.$route.query.phoneNumber) {
      this.phoneNumber = this.$route.query.phoneNumber
    } else {
      this.phoneNumber = '暂未绑定手机号'
    }
  },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () { }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { }, // 如果页面有keep-alive缓存功能，这个函数会触发
  // 方法集合
  methods: {
    // 发送验证码
    seedPin () {
      // 校验输入的手机号
      if (!this.regex.test(this.confirmForm.phoneNum)) {
        this.$toast('请输入正确的手机号')
        return false
      }
      this.bindJudgment()
    },
    // 判断手机号是否已经绑定
    bindJudgment () {
      const info = {
        phoneNumber: this.confirmForm.phoneNum
      }
      this.$selfInfo.changeBindPhoneNumber(info).then(res => {
        this.pinButDisabled = true

        const that = this
        that.$selfInfo.sendSms(that.confirmForm).then(() => {
          that.$toast('发送成功')
          that.pinButText = `重新发送 ${that.second}s`
          that.setButTime()
        }).catch(() => {
          that.$toast.fail('发送失败')
          that.pinButDisabled = false
        })
      })
    },
    // 确认更换按钮
    handleClickConfirm () {
      // 校验输入的手机号
      if (!this.regex.test(this.confirmForm.phoneNum)) {
        this.$toast('请输入正确的手机号')
        return false
      }
      if (!this.confirmForm.pinNumber && !this.confirmForm.phoneNum) {
        this.$toast('请输入手机号或验证码')
        return false
      }
      this.$selfInfo.changePhoneNumber(this.confirmForm).then(() => {
        // 如果session中的userInfo为'undefined' 需要修改userInfo
        if (sessionStorage.getItem('userInfo') === 'undefined') {
          this.userInfo.phoneNumber = this.confirmForm.phoneNum
          sessionStorage.removeItem('userInfo')
          sessionStorage.setItem('userInfo', JSON.stringify(this.userInfo))
        } else {
          const userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
          userInfo.phoneNumber = this.confirmForm.phoneNum
          sessionStorage.setItem('userInfo', JSON.stringify(userInfo))
        }
        this.$toast({ message: '绑定成功' })
        this.$router.go(-1)
      })
    },
    // 倒计时设定
    setButTime () {
      const ctrl = setInterval(() => {
        this.second--
        this.pinButText = `重新发送 ${this.second}s`
        if (this.second <= 0) {
          clearInterval(ctrl)
          this.pinButDisabled = false
          this.pinButText = '发送验证码'
        }
      }, 1000)
    },
    // 顶部返回
    handleClickTopBack () {
      this.$router.go(-1)
    }
  }
}
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.body {
  width: 100%;
  height: 100%;
  .lineOne {
    padding-top: 20px;
    width: 100%;
    height: 90px;
    display: flex;
    justify-content: center;
    img {
      height: 90px;
      width: 90px;
    }
  }
  .lineTwo {
    width: 100%;
    height: 40px;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .button {
    color: #ffffff;
    background: #19a9fc;
  }
  .img {
    padding-top: 3px;
    padding-right: 10px;
  }
  .confirmBut-box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 93.6%;
    margin-left: 3.2%;
    height: 40px;
    margin-top: 30px;
    background: #19a9fc;
    border-radius: 5px;
    font-size: 17px;
    line-height: 40px;
    font-weight: Regular;
    text-align: center;
    color: #ffffff;
  }
}
</style>
